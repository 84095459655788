<template>
    <app-layout>
        <template v-slot:header>
            <Header :title="$t('batch_score_entry_note')"
                    :isColumns="false"
                    :isFilter="false"
            >
            </Header>
        </template>
        <template v-slot:header-mobile>
            <HeaderMobile :title="$t('batch_score_entry_note')"
                          :isColumns="false"
                          :isFilter="false"
            >
            </HeaderMobile>
        </template>
        <div>
            <b-col v-if="!students.length" class="d-flex justify-content-center align-items-center"
                   style="min-height: 400px">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
            </b-col>
            <div v-else>
                <ValidationObserver ref="studentExamForm">

                    <b-table-simple class="border w-100" small caption-top responsive hover bordered>
                        <thead>
                        <tr>
                            <th rowspan="2" class="text-center justify-center width-50">{{ $t('student_number') }}</th>
                            <th rowspan="2" class="text-center justify-center width-40">{{
                                    $t('name') + ' ' + $t('surname')
                                }}
                            </th>
                            <th v-for="(exam,key) in examTypeArray" :key="'header-' + exam.id"
                                :colspan="exam.number_of_exams" class="border-bottom text-center">
                                <div class="d-flex flex-column align-items-center mb-2">
                                    <span>{{ exam.name }}</span>
                                </div>
                            </th>
                            <th rowspan="2" class="text-center justify-center width-40">{{
                                   $t('grade')
                                }}
                            </th>
                        </tr>
                        <tr>
                            <template v-for="(exam,j) in examTypeArray">
                                <th v-for="(i, key) in exam.exams" :key="'checkbox-' + exam.id + '-' + i.rank" class="mobile-th">
                                    <div class="d-flex align-items-center justify-content-center mb-2">
                                        <b-form-checkbox
                                            :disabled="i.has_finalize"
                                            @change="examStatusCheck(exam, i, key)"
                                            :id="'checkbox-' + exam.id + '-' + i.rank"
                                            v-model="exam.exams[key].is_active"
                                        >
                                            <span class="small-text">{{ i.date ? toLocaleDate(i.date) : null }}</span>
                                        </b-form-checkbox>
                                    </div>

                                    <div class="d-flex responsive-text">
                                        <span class="small-text text-nowrap">{{ $t('did_he_take_the_exam') }}</span>
                                        <span class="small-text text-nowrap">{{ exam.name }} - {{ key + 1 }}</span>
                                    </div>
                                </th>
                            </template>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(student, index) in students" :key="student.student_number + '-'+ index"
                            class="align-middle text-center">
                            <td>
                                {{ student.student_number }}
                            </td>
                            <td class="text-center align-center">{{ student.name }} {{ student.surname }}</td>
                            <template v-for="(exam,i) in examTypeArray">
                                <td v-for="(examItem, key) in exam.exams" :key="'td-' + exam.id + '-' + examItem.rank"
                                    class="width-30">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ValidationProvider
                                            :name="`attended-${exam.id}-${examItem.rank}-${index}`"
                                            rules="required"
                                            v-slot="{ valid, errors }"
                                        >
                                            <b-form-checkbox
                                                :disabled="!examItem.is_active || examItem.has_finalize"
                                                v-if="selectedExams[exam.id] && selectedExams[exam.id][key + 1]"
                                                :id="'checkbox-' + exam.id + '-' + key"
                                                :value="1"
                                                :unchecked-value="0"
                                                @change="examStatusChange(selectedExams[exam.id][key + 1][index])"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="selectedExams[exam.id][key + 1][index].attended"
                                            >
                                            </b-form-checkbox>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>

                                        <div class="width-100">
                                            <ValidationProvider
                                                :name="`score-${exam.id}-${examItem.rank}-${index}`"
                                                :rules="examItem.is_active && selectedExams[exam.id][key + 1][index].attended ? 'required|min_value:0|max_value:100' : ''"
                                                v-slot="{ valid, errors }"
                                            >
                                                <b-form-input
                                                    v-if="selectedExams[exam.id] && selectedExams[exam.id][key + 1]"
                                                    :placeholder="$t('grade_entry')"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    @input="restrictScore($event, exam.id, key + 1, index)"
                                                    v-model="selectedExams[exam.id][key + 1][index].score"
                                                    :disabled="!selectedExams[exam.id][key + 1][index].attended || !examItem.is_active || examItem.has_finalize"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </td>
                            </template>
                                <td class="width-30">
                                    {{student.score?student.score:'-'}}
                                </td>
                        </tr>
                        </tbody>
                        <b-tfoot>
                            <tr class="border">
                                <td colspan="2"></td>
                                <template v-for="(exam) in examTypeArray">
                                    <td v-for="(i,key) in exam.exams" class="text-center">
                                        <div class="d-flex btn-block flex-column align-items-center">
                                            <b-button
                                                v-show="i.is_active && !i.has_finalize"
                                                variant="success"
                                                class="mb-2 w-100"
                                                @click="saveExams(exam, i.rank,key)"
                                            >
                                                Kaydet
                                            </b-button>
                                            <b-button
                                                v-show="(i.id && !i.has_finalize && i.is_finalize)"
                                                variant="danger"
                                                class="w-100"
                                                @click="saveFinalize(i)"
                                            >
                                                Kesinleştir
                                            </b-button>
                                        </div>

                                    </td>
                                </template>
                            </tr>
                        </b-tfoot>

                    </b-table-simple>
                </ValidationObserver>


            </div>

        </div>
    </app-layout>


</template>

<script>

import qs from "qs";
import ExamDefinationService from "@/services/ExamDefinationService";

export default {
    data() {
        return {
            selectedExams: {},
            allExams: [],
            examTypeArray: [],
            examScore: [],
            students: []
        };
    },
    metaInfo() {
        return {
            title: this.$t('batch_score_entry_note'),
        }
    },
    created() {
        this.getSectionInformation();
    },
    methods: {
        async getExamOptions() {
            const examOptions = {};
            this.allExams.forEach(item => {
                if (!examOptions[item.id]) {
                    examOptions[item.id] = {

                        id: item.id,
                        name: item.exam_type_text,
                        section_id: item.section_id,
                        effect_ratio: item.effect_ratio,
                        number_of_exams: item.number_of_exams,
                        exams: []
                    };
                }

                for (let i = 0; i < item.number_of_exams; i++) {
                    const examData = item.exams && item.exams[i];
                    examOptions[item.id].exams.push({
                        name: item.exam_type_text + '-' + (i),
                        id: examData ? examData.id : null,
                        rank: examData ? examData.rank - 1 : i,
                        is_active: false,
                        has_finalize: examData ? examData.is_finalized : false,
                        is_finalize: false,
                        date: examData ? examData.date : null
                    });
                }

                examOptions[item.id].exams.sort((a, b) => {
                    return a.rank - b.rank;
                });
            });

            this.examTypeArray = Object.values(examOptions);

        },
        async getSectionInformation() {
            const config = {
                params: {
                    filter: {
                        section_id: this.$route.params.sectionId,
                    },
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            const [exams, studentList, examScore] = await Promise.all([
                ExamDefinationService.getAll(config),
                ExamDefinationService.getStudents(this.$route.params.sectionId),
                ExamDefinationService.getExamScore(config),
            ]);
            this.allExams = exams.data.data;
            this.students = studentList.data.data;
            this.examScore = examScore.data.data
            await this.getExamOptions()
            if (this.examScore.length > 0) {
                await this.initializeAttendance(true)
            } else {
                await this.initializeAttendance(false)
            }
        },
        async initializeAttendance(isExamScore) {
            if (!this.allExams || !this.students) return;
            this.examTypeArray.forEach(value => {
                this.$set(this.selectedExams, value.id, {});
                for (let i = 1; i <= value.number_of_exams; i++) {
                    this.$set(this.selectedExams[value.id], i, this.students.map(student => ({
                        student_program_id: student.student_program_id,
                        attended: 1,
                        score: null,
                        calculate_score:student.score
                    })));
                }
            });
            if (isExamScore) {
                const examScoreData = await this.getExamScore();
                Object.entries(examScoreData).forEach(([exam_definition_id, exam_rank]) => {
                    if (this.selectedExams[exam_definition_id]) {
                        Object.entries(exam_rank).forEach(([rank, scoreItems]) => {
                            if (this.selectedExams[exam_definition_id][rank]) {
                                scoreItems.forEach(item => {
                                    const index = this.selectedExams[exam_definition_id][rank].findIndex(
                                        entry => entry.student_program_id === item.student_program_id
                                    );
                                    if (index !== -1) {
                                        this.$set(this.selectedExams[exam_definition_id][rank], index, {
                                            student_program_id: item.student_program_id,
                                            attended: item.attended ? 1 : 0,
                                            score: item.score,
                                            calculate_score:item.calculate_score,
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
        async getExamScore() {
            const object = {};
            this.examScore.forEach(value => {
                if (!object[value.exam_definition_id]) {
                    object[value.exam_definition_id] = {};
                }

                if (!object[value.exam_definition_id][value.exam_rank]) {
                    object[value.exam_definition_id][value.exam_rank] = [];
                }

                object[value.exam_definition_id][value.exam_rank].push({
                    student_program_id: value.student_program_id,
                    attended: value.attended,
                    score: value.score,
                });
            });
            return object;

        },
        examStatusCheck(exam, i, key) {
            let count = 0;
            let start = 0;
            this.selectedExams[exam.id][key + 1].forEach((item) => {
                if ((!item.attended && !item.score) || item.score) {
                    exam.exams[key].is_finalize = true
                } else {
                    exam.exams[key].is_finalize = false
                }
            })

            if (exam.exams.length > 1) {
                for (let i = 0; i < key; i++) {
                    if (!exam.exams[key - 1]) {
                        break
                    }
                    this.selectedExams[exam.id][i + 1].forEach((item) => {
                        if ((!item.score && item.attended) ||  !exam.exams[key - 1].id) {
                            start += 1
                            exam.exams[key].is_active = false
                        }
                    })
                }

            }
            if (start > 0) {
                this.$toast.error(this.$t('previous_exams_must_be_taken'))
                start = 0
                return
            }
            this.examTypeArray.forEach(value => {
                value.exams.forEach(ex => {
                    if (ex.is_active) {
                        count++;
                    }
                });
            });
            if (count > 0) {
                this.examTypeArray.forEach(value => {
                    value.exams.forEach(ex => {
                        ex.is_active = false;
                    });

                });
                this.examTypeArray.forEach(value => {
                    if (value.id === exam.id) {
                        value.exams.forEach(ex => {
                            if (ex.rank === i.rank) {
                                ex.is_active = !ex.is_active;
                            }
                        });
                    }
                });
            } else {
                this.examTypeArray.forEach(value => {
                    if (value.id === exam.id) {
                        value.exams.forEach(ex => {
                            if (ex.rank === i.rank) {
                                ex.is_active = false
                            }
                        });
                    }
                });
            }
        },
        async saveExams(exam, exam_rank) {
            const isValid = await this.$refs.studentExamForm.validate();
            if (!isValid) return
            // if (!this.checkPermission('eslattendance_store')) return this.$toast.error('unauthorized_operation');
            const formData = {
                exam_definition_id: exam.id,
                rank: exam_rank + 1,
                scores: this.selectedExams[exam.id][exam_rank + 1]
            };
            try {
                const res = await ExamDefinationService.examScoreSave(formData);
                this.$toast.success(this.$t("api." + res.data.message));
                exam.exams[exam_rank].is_active = false;
                exam.exams[exam_rank].is_finalize = false;
                await this.getSectionInformation();
            } catch (error) {
                this.$toast.error(error.data.message);
            }
        },
        examStatusChange(examStatus) {
            if (!examStatus.attended) {
                examStatus.score = null
            }
        },
        async saveFinalize(i) {
            if (!i.id) return
            try {
                const res = await ExamDefinationService.saveExamPublish(i.id);
                this.$toast.success(this.$t("api." + res.data.message));
                i.is_finalize = false;
                await this.getSectionInformation();
            } catch (error) {
                this.$toast.error(error.data.message);
            }

        },
        restrictScore(event, examId, rank, index) {
            let value = event
            if (isNaN(value) || value < 0) {
                this.$set(this.selectedExams[examId][rank], index, {
                    ...this.selectedExams[examId][rank][index],
                    score: 0
                });
            } else if (value > 100) {
                this.$set(this.selectedExams[examId][rank], index, {
                    ...this.selectedExams[examId][rank][index],
                    score: 100
                });
            } else {
                this.$set(this.selectedExams[examId][rank], index, {
                    ...this.selectedExams[examId][rank][index],
                    score: value
                });
            }
        }


    },

};
</script>
<style scoped>
.small-text {
    font-size: 0.7rem;
    line-height: 1;
}

.text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile-th {
    padding: 0.2rem !important;
    min-width: 20px !important;
    max-width: 20px !important;
}

.responsive-text {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    margin: 0 !important;
    gap: 2px;
}

@media (max-width: 768px) {
    .responsive-text {
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
    }

    .mobile-th {
        min-width: 50px !important;
        max-width: 70px !important;
    }

    .small-text {
        font-size: 0.6rem;
    }
}

@media (max-width: 480px) {
    .responsive-text {
        gap: 0;
    }

    .mobile-th {
        min-width: 40px !important;
        max-width: 60px !important;
    }
}
</style>
